* {
  box-sizing: border-box;
}

/* Ensure body and html take up the full width and height */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-family: 'Gothic A1';
}

.media-header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 0px;
  z-index: 1;
}

/* .media-feed p {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
} */

.view-saved-button {
  position: relative;
  background-color: #93AFAE; /* Adjust the background color */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Make sure it stays above other content */
}

.view-saved-button:hover {
  background-color: #6F8C8B; /* Change color on hover */
}


.media-profile-picture {
  width: 40px; /* Smaller size */
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  /* border: 1px solid #FFF; */
  background-color: black;
  overflow: hidden;
  margin: 10px;
  display: flex;
}

.media-content-wrapper {
  position: relative;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 0px;
  /* overflow: hidden; */
  /* max-height: 80vh; */
}

.profile-icon {
  width: 70px; /* Smaller size */
  height: 70px;
}

.username {
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
}

.media-content {
  width: 100%;
  /* max-width: 100%; */
  height: auto;
  object-fit: contain;
  display: block;
}

.media-caption {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: black;
  padding: 0px;
  z-index: 1;
  box-sizing: border-box;
}

.media-caption p {
  font-size: 16px;
  /* font-weight: bold; */
  margin: 5px;
  margin-bottom: 0px;
}

.media-reactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  padding: 5px 0px;
  margin: 0;
}

.reaction-buttons {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
}

.save-collection-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: black;
  margin-right: 10px;
  margin-top: -10px;
  padding: 0px;
}

.save-collection-button .material-icons {
  font-size: 28px;
}

.like-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  margin-right: 5px;
  margin-left: 10px;
  color: black;
}

.like-button .material-icons {
  font-size: 28px;
  padding: 0px;
}

.media-header .material-icons {
  font-size: 28px;
  justify-content: center;
  align-items: center;
}

.like-button.liked .material-icons {
  animation: fadeUp 0.6s ease;
  color: red; /* Change color to red when liked */
}

@keyframes fadeUp {
  from {
    opacity: 1;
    transform: translateY(0px) scale(0.8);
  }
  to {
    opacity: 0;
    transform: translateY(-100px) scale(1);
  }
}

.like-count {
  margin-left:5px;
  font-size: 16px;
  color: black;
  padding: 0px;
}

.comment-count {
  margin-left: 5px;
  font-size: 16px;
  color: black;
  padding: 0px;
}

.comments-section {
  margin-top: 8px;
  padding: 0 10px;
  width: 100%; /* Ensure the comments don't extend beyond the container */
  word-wrap: break-word; /* Break long words and wrap them */
  overflow-wrap: break-word; /* Handle long unbreakable words */
}

.comment-button {
  display: flex;
  align-items: center;
  background: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: black;
  font-size: 26px;
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
}

.comment-item {
  display: block;
  margin-bottom: 6px;
  max-width: 100%; /* Ensure the individual comment doesn't extend beyond its container */
  text-align: left;
}

.comment-user {
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px; /* Smaller font like Instagram */
  color: black; /* Darker color for username */
  display: inline;
}

.comment-text {
  font-size: 16px;
  color: black;
  word-wrap: break-word; /* Wrap long comments */
  overflow-wrap: break-word; /* Handle long unbreakable words */
  white-space: normal; /* Allow wrapping */
  max-width: 100%; /* Ensure the text wraps and doesn't extend beyond the container */
  display: inline; /* Keep it inline with the username */
  text-align: left; /* Ensure comment text is aligned to the left */
}

.comments-section .comment-text {
  margin-left: 5px;
  font-size: 16px;
}

.comment-input-section .submit-sms-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  margin-top: 5px;
  padding: 0 10px;
  font-size: 28px;
}

.comment-input-section .submit-button:hover {
  transform: scale(0.95);
}

.comment-input-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.comment-input {
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
  outline: none;
  width: 90%;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  background-color: #FFF;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #FFF;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.delete-button .material-icons,
.comment-button .material-icons {
  font-size: 28px;
}

.delete-comment-button {
  float: right;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.delete-comment-button .material-icons {
  font-size: 20px;
}

.delete-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirm-delete {
  background-color: red;
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.cancel-delete {
  background-color: black;
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Ensure visibility of chat-bubble */
.chat-bubble {
  display: flex;
  flex-direction: column; /*Align content vertically */
  /* align-self: flex-start; Center content horizontally */
  justify-content: center; /* Center content vertically */
  width: auto;
  min-width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--accent-color);
  font-size: 26px;
  border-radius: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  position: relative;
  /* border: 3px solid transparent;
  background-image: linear-gradient(white, white),
                    linear-gradient(45deg, #281f1a, #936B4C, #281f1a);
  background-origin: border-box;
  background-clip: padding-box, border-box;  */
}

.chat-container {
  position: relative;
  min-width: 100%;
  margin-top: 20px;
}

.prompt-description {
  flex-grow: 1;
  font-size: 18px;
  color: #FFF;
  line-height: 1.4;
}

.prompt-timestamp {
  position: absolute;
  top: -20px;
  right: 5px;
  font-size: 12px;
  color: black;
}


.chat-bubble p {
  margin: 0; /* Remove default margin from the paragraph */
  padding: 10px 0;
  font-size: 26px;
  width: 100%; /* Ensure the text takes full width */
}

.chat-bubble::before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 10px;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: var(--accent-color) transparent transparent transparent;
}


.voice-note-container {
  display: flex;
  justify-content: space-between;  /* horizontal center */
  align-items: center;
  width: 100%;
  margin-top: 60px;
  height: 7vh;
  gap: 5px;
  background-color: color-mix(in srgb, var(--accent-color) 50%, transparent);
  overflow: hidden;
} 

.voice-note-container button {
 background: transparent;
 border: none;
}

.voice-note-container .material-icons-outlined {
  color: white;
  background: transparent;
  border: none;
  font-size: 28px;
}

.voice-note-container div {
  flex: 1;
  margin: 0;
  padding: 0px;
}

.voicenote-media-header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: color-mix(in srgb, var(--accent-color) 50%, transparent);
  color: white;
  padding: 0px;
  z-index: 1;
}


/*-------------------------------- DARK MODE ------------------------------------------------*/
/* @media (prefers-color-scheme: dark) {
  body, html {
    background-color: white;
    color: white;
  }

  .media-header {
    background-color: white;
  }

  .media-list .media-item {
    background-color: white;
  }
  
  .media-profile-picture {
    border: 2px solid #C62828;
    background-color: #121212;
  }
  
  .username {
    color: #C62828;
  }
  
  .media-reactions {
    background-color: white;
  }
  
  .reaction-buttons {
    color: #E0E0E0;
  }
  
  .like-button {
    color: #F83908;
  }
  
  .media-header .material-icons {
    font-size: 50px;
    justify-content: center;
    align-items: center;
  }
  
  .like-button.liked .material-icons {
    color: #F83908;
  }
  
  .like-count {
    color: #F83908;
  }
  
  .`count {
    color: #F83908;
  }
  
  .comments-section {
    background-color: #121212;
    color: #E0E0E0;
  }
  
  .comment-button {
    color: #F83908;
  }
  
  .comment-user {
    color: black;
    display: inline;

  }
  
  .comment-text {
    color: black;
  }
  
  .comments-section {
    background-color: white;

  }

  .comments-section .comment-text {
    color: black;
  }
  
  .comment-input-section .submit-button {
    color: #F83908;
  }
  
  .comment-input-section {
    background-color: white;
  }
  
  .comment-input {
    color: black;
    background-color: white;
  }
  
  .delete-button .material-icons {
    font-size: 24px;
    color: #F83908;
  }
  
  .confirmation-content {
    background-color: white;
  }
  
  .chat-bubble {
    background-color: white;
  }

  .chat-container {
    background-color: white;
  }
  
  .prompt-timestamp {
    color: #E0E0E0;
  }
} */