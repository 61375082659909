/* SavedMediaFeed.css */

.saved-media-feed {
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    padding-top: 0px;
    max-width: 100%;
    overflow-y: auto;
    z-index: 100;
    font-family: 'Gothic A1';
  }

  .saved-media-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}

.saved-media-content {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.saved-media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 90%;
    align-items: center;
}

.download-all-container {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.download-all-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #93AFAE;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-all-button:hover {
  background-color: #6F8C8B;
}

.saved-media-feed p {
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-family: 'Gothic A1';
}