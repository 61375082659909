/* ItineraryPage.css */

.itinerary-page {
  padding: 20px;
  background-color: #FFF;
  min-height: 100vh;
  font-family: 'Gothic A1';
}

.itinerary-page h1 {
  text-align: center;
  color: var(--accent-color);
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.date-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  /* border-bottom: 1px solid black; */
}

.date-navigation select {
  font-size: 18px;
  padding: 10px;
  font-weight: 600;
  color: black;
  text-align: center;
  border: none;
  border-radius: 0px;
  background-color: #fff;
}

.day-section {
  margin-bottom: 40px;
}

.day-section h2 {
  font-size: 18px;
  font-weight: 600;
  color: black;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
}

.events-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;
}

.event-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
}

.event-time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ebcd61;
  flex-shrink: 0;
}

.event-time p {
  margin: 0;
  font-size: 16px;
}

.event-details > div {
  display: flex;
  align-items: center;
  gap: 0 5px;
  width: 100%;
  margin-bottom: 15px;
}

.event-details h3, .key-people-container h3, .key-locations-container h3 {
  margin: 3px 0;
  color: black;
  font-size: 18px;
}

.event-details p {
  margin: 3px 0;
  color: black;
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  
}

.event-details a {
  color: var(--accent-color);
  text-decoration: none;
  word-wrap: break-word;
}

.event-details a:hover {
  text-decoration: underline;
}

.event-contact-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Key People Styles */
.key-people-container, .key-locations-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;
}

.person-card, .location-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;  
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
}

.person-card p, .location-card p {
  margin: 0px 0;
  color: black;
  display: flex;
  align-items: center;
  gap: 5px;
}

.person-card a {
  color: var(--accent-color);
  text-decoration: none;
}

.person-card a:hover {
  text-decoration: underline;
}

.tabs-itinerary {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 30px;
  margin-top: 0px;
  max-width: 100%;
}

.tab-button-itinerary {
  padding: 5px;
  background-color: transparent;
  justify-content: center;
  color: var(--accent-color);
  border: none;
  border-radius: 0%;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
  width: 25%;
}

.tab-button-itinerary .material-icons-outlined,
.tab-button-itinerary .material-icons {
  color: var(--accent-color);
  font-size: 30px;
}

.tab-button-itinerary.active {
  background-color: transparent;
  border-bottom: 3px solid var(--accent-color);
}

.tab-button-itinerary:hover {
  background-color: transparent;
  border-bottom: 3px solid var(--accent-color);
}

.tab-content-itinerary {
  margin-top: 20px;
}

/* Dark Mode */
/* @media (prefers-color-scheme: dark) {
  .itinerary-page {
    background-color: #121212;
    color: #ffffff;
  }

  .itinerary-page h1 {
    color: #ebcd61;
  }

  .day-section h2 {
    color: #ebcd61;
    border-bottom: 2px solid #6F8C8B;
  }

  .event-card {
    background-color: #1e1e1e;
    border: 1px solid #444;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  }

  .event-time {
    color: #ebcd61;
  }

  .event-details h3 {
    color: #ebcd61;
  }

  .event-details p {
    color: #ccc;
  }

  .event-details a {
    color: #ebcd61;
  }

  .event-details a:hover {
    text-decoration: underline;
  }
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24;
}

.material-icons-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24;
} */
