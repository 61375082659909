body, html {
  margin: 0;
  padding: 0;
  font-family: 'Gothic A1';
  box-sizing: border-box;
}

#features-entercode {
  padding: 20px;
  margin-top: 3rem;
  background-color: #fff;
  text-align: center;
}

/* Enter Event Code Section */
.enter-event-code {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;
}

.enter-event-code h2 {
  margin-bottom: 1rem;
  font-size: 20px;
  color: #444;
  font-weight: normal;
}

.enter-event-code form {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  background-color: #fff;
}

.enter-event-code input {
  padding: 10px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); 
}

.enter-event-code form .event-code-submit-button {
  padding: 0 10px; /* Adjust padding to match the icon size */
  font-size: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #93afae;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.enter-event-code form .event-code-submit-button:hover {
  color: #5e807f;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
