html, body {
  margin: 0;
  padding: 0;
  font-family: 'Gothic A1';
}

.event-hub-page {
  padding: 20px;
  min-height: 100vh;
  font-family: 'Gothic A1';
  padding-bottom: 50px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.tabs-itinerary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0;
  width: 100%;
  max-width: 100%;
}

.tab-button-itinerary {
  display: flex;
  padding: 10px 0; /* Adds height while keeping width consistent */
  font-size: 18px;
  font-weight: 600;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  text-align: center;
}

.tab-button-itinerary.active {
  background-color: var(--accent-color);
}

h2 {
  margin-top: 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: black;
}

h3 {
  margin-top: 0px;
  text-align: left;
  font-size: 18px;
  color: black;
}

.upload-prompt-form h2 {
  margin-bottom: 20px;
}

.sms-form-container, .upload-prompt-form, .scheduled-prompts {
  background-color: #FFF;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sms-form-container input,
.sms-form-container textarea,
.upload-prompt-form textarea {
  margin-top: 10px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  background-color: #fafafa;
}

.sms-form-container button {
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  padding: 0px;
  border-radius: 4px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.sms-form-container h2 {
  margin-bottom: 20px;
}

.sms-form-container button:hover {
  transform: scale(0.95);
}

.input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

/* Upload Prompt Form */
.upload-prompt-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-guest-form {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
  margin: 0 auto;
  max-width: 90%;
  background-color: #fff;
}

.add-guest-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: auto;
  background-color: #fff;
}

.add-guest-form input {
  max-width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  background-color: #fafafa;
}

.add-guest-form form button {
  font-size: 16px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px;
}

.add-guest-form form button:hover{
background-color: var(--accent-color);
}

.upload-prompt-form button {
  font-size: 16px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px;
}

.upload-prompt-form button:hover {
  background-color: var(--accent-color);
}

.upload-prompt-form .form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

/* Prompt Table */
.prompts-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.prompts-table th,
.prompts-table td {
  padding: 10px;
  width: auto;
  border: 1px solid #ddd;
  text-align: left;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.prompts-table th {
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-align: center;
}

.prompts-table td {
  font-size: 12px;
  white-space: normal;
}

.prompts-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* .prompts-table tr:hover {
  background-color: #f1f1f1;
} */

/* Error & Success Messages */
.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}

.success-message {
  color: green;
  font-size: 14px;
  text-align: center;
}

.submit-sms-button {
  color: var(--accent-color);
}

.comment-input-section .submit-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  margin-top: 5px;
  padding: 0 10px;
  font-size: 24px;
}

.comment-input-section .submit-button:hover {
  transform: scale(0.95);
}

.form-group .custom-datepicker::placeholder {
  font-size: 14px;
}

.form-group .custom-datepicker {
  width: 50%;
  display: flex;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 auto;
  padding: 5px;
}

.date-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0px;
}

.date-filter-input {
  width: 100%;
  max-width: 120px;
  padding: 0px;
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
}

.clear-filter-button {
  display: flex;
  align-items: center;
  background-color: var(--accent-color);
  color: white;
  border: none;
  margin-top: 5px;
  border-radius: 4px;
}

.date-filter label {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
  color: black; /* Adjust the color as needed */
}

.date-filter-dropdown {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fafafa;
  appearance: none;
}

.date-filter-dropdown:focus {
  border-color: var(--accent-color); /* Adjust the color for focus state */
  outline: none;
}

.search-input {
  padding: .25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  font-size: 12px;
}

.search-input:focus {
  outline: none;
}

.editable-cell {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  background-color: #f9f9f9;
}

.editable-cell:focus {
  border: 1px solid #ebcd61;
  background-color: #fff;
}

.save-changes-button {
  padding: 8px 16px;
  background-color: #ebcd61;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
}

.save-changes-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.prompts-table .delete-guest-button {
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s;
}

.prompts-table .delete-guest-button:active {
  transform: scale(0.9);
}

.prompts-table .delete-guest-button:hover {
  background-color: transparent;
}

.prompts-table .delete-guest-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.prompts-table .delete-guest-button .material-icons {
  font-size: 18px;
}

.prompts-table .narrow-column {
  width: 10px;
  text-align: left;
  padding: 0;
  justify-content: center;
  background-color: #ffffff;
  border: none;
}

.prompts-table .narrow-column:hover {
  background-color: transparent;
  border: none;
}

.prompts-table .narrowish-column {
  width: 20%;
  text-align: center;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap:normal;
}

.prompts-table .narrower-column {
  text-align: center;
  width:30%;
  justify-content: center;
  box-sizing: border-box;
  overflow-wrap:normal;
}

/* Dark Mode */
/* @media (prefers-color-scheme: dark) {
  .event-hub-page {
    background-color: #121212;
  }

  .tabs-itinerary {
    background-color: #1e1e1e;
  }

  .tab-button-itinerary {
    background-color: #333;
    color: white;
  }

  .tab-button-itinerary.active {
    background-color: #6F8C8B;
  }

  .sms-form-container,
  .upload-prompt-form,
  .scheduled-prompts {
    background-color: #1e1e1e;
    border: 1px solid #444;
  }

  .sms-form input,
  .sms-form textarea,
  .upload-prompt-form textarea {
    background-color: #333;
    color: white;
    border: 1px solid #555;
  }

  .prompts-table th,
  .prompts-table td {
    border: 1px solid #444;
  }

  .prompts-table th {
    background-color: #222;
  }

  .prompts-table tr:nth-child(even) {
    background-color: #333;
  }

  .prompts-table tr:hover {
    background-color: #444;
  }

  h1, h2 {
    color: white;
  }
} */
