.uploadMediaContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Gothic A1';
  /* margin-bottom: 50px; */
  background-color: #FFF;
  overflow: hidden;
}

.uploadMediaHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dbdbdb; 
}

.cancelButton,
.postButton {
  background: transparent;
  border: none;
  font-weight: 600;
  cursor: pointer;
  color: #0095f6;
  font-size: 1rem;
}

.postButton:disabled {
  background-color: transparent;
  border: none;
  font-weight: 600;
  cursor: not-allowed;
  color: #ccc;
  font-size: 1rem;
}

.cancelButton:hover,
.postButton:hover {
  opacity: 0.7;
}

.cancelButton:active,
.postButton:active {
  transform: scale(0.95);
}

.headerTitle {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

/* Body */
.uploadMediaBody {
  display: flex;
  flex-direction: column;
}

/* If no file selected yet */
.fileInputArea {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectImageLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #aaa;
}

.selectImageLabel .material-icons {
  font-size: 48px;
  margin-bottom: 0.5rem;
}

/* Once image is selected */
.imagePreviewContainer {
  width: 100%;
  max-height: 100vh;
  margin-bottom: 120px;
  overflow: hidden;
  background: #fafafa;
}

.imagePreview {
  max-width: 100%;
  max-height: auto;
  object-fit: contain;
  display: block;
}

/* Caption area */
.captionContainer {
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 50px;
  padding: 5px;
  background-color: #fff;
}

.captionInput {
  width: 100%;
  border: none;
  resize: none;
  font-size: 1rem;
  outline: none;
  padding-top: 20px;
}

.captionInput::placeholder {
  color: #999;
}
